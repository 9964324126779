import Work1 from "../../assets/care_services.png";
import Work2 from "../../assets/bruce_gyimah.png";
import Work3 from "../../assets/kwadden.png";
import Work4 from "../../assets/lawsghana.png";
import Work5 from "../../assets/swifter.png";
import Work6 from "../../assets/trust_fund.png";
import Work7 from "../../assets/akantant.png";
import Work8 from "../../assets/nature.png";
// import Work9 from "../../assets/sys_manage.png";
// import Work10 from "../../assets/ticket.png";

export const projectsData = [
    {
      id: 1,
      image: Work1,
      title: "Care Services",
      category: "wordpress apps",
      url: "https://independentcareservices.com.au/"
    },
    {
      id: 2,
      image: Work2,
      title: "Bruce Gyimah",
      category: "wordpress apps",
      url: "https://brucegyimah.com/"
    },
    {
      id: 3,
      image: Work3,
      title: "Kwadden",
      category: "wordpress apps",
      url: "https://kwadden.com/"
    },
    {
      id: 4,
      image: Work4,
      title: "Laws Ghana",
      category: "apps",
      url: "https://lawsghana.com"
    },
    {
      id: 5,
      image: Work5,
      title: "Photo Gallery",
      category: "in-development",
      url: "https://swifter.zoelyfe.com"
    },
    {
        id: 6,
        image: Work6,
        title: "Trust Fund System",
        category: "in-development",
        url: "http://trustfund.zoelyfe.com/admin/login"
      },
      {
        id: 7,
        image: Work7,
        title: "Akantant",
        category: "apps",
        url: "https://akantant.com"
      },
      {
        id: 8,
        image: Work8,
        title: "Nature Commodities",
        category: "apps",
        url: "https://https://nature-commodities.zoelyfe.com"
      },
      // {
      //   id: 9,
      //   image: Work9,
      //   title: "Performance Management System",
      //   category: "app",
      //   url: "https://www.gcaa.com.gh/pms/public/index.php"
      // },
      // {
      //   id: 10,
      //   image: Work10,
      //   title: "Ticketing System",
      //   category: "app",
      //   url: "https://ticketmiller.com/"
      // }, 
    
  ];

  export const projectsNav = [
    {
        name: 'all',
    },
    {
        name: 'wordpress apps',
    },
    {
        name: 'apps',
    },
    {
        name: 'in-development',
    },
  ];
  