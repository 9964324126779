import React from 'react'

const Social = () => {
  return (
    <div className="home__social">
        <a href="" className="home__social-icon" target="_blank"><i class="uil uil-instagram"></i></a>
        <a href="" className="home__social-icon" target="_blank"><i class="uil uil-dribbble"></i></a>
        <a href="" className="home__social-icon" target="_blank"><i class="uil uil-github-alt"></i></a>
    </div>
  )
}

export default Social