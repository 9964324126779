// import Image1 from '../../assets/testimonial1.png';
// import Image2 from '../../assets/testimonial2.png';
// import Image3 from '../../assets/testimonial3.png';

export const Data = [
    {
        
        id: 1,
        // image: Image1,
        title: "Akantant",
        description:
            "A really good job with Akantant system. All aspects of the projects were followed step by step and with good results",
        },
        {
        id: 2,
        // image: Image2,
        title: "Laws Ghana",
        description:
            "Built a reliable and educative system for public use, lawsghana.com. A platform that provides fast and accurate insight into the laws of Ghana",
        },
        {
        id: 3,
        // image: Image3,
        title: "Nature Commodities",
        description:
            "A platform which provides tropical medicinal plants, wild forestry organic and botanical produce",
        },
    
];